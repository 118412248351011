import { Injectable } from '@angular/core';
import { Observable, Subject, timer, of, from } from 'rxjs';
import { takeUntil, repeatWhen, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  readonly observable$: Observable<number>;
  private readonly _stop = new Subject<void>();
  private readonly _start = new Subject<void>();

  constructor(delay:number) {
    this.observable$ = timer(0, delay)
      .pipe(
        takeUntil(this._stop),
        repeatWhen(() => this._start)
      );
  }

  start(): void {
    this._start.next();
  }

  stop(): void {
    this._stop.next();
  }
}
