import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(){

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        let customReq = request;
        if (token) {
            const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
            customReq = request.clone({ headers});
        }
        return next.handle(customReq);
    }
}
